/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const helpListHelps = /* GraphQL */ `
  query HelpListHelps($limit: Int, $nextToken: String) {
    helpListHelps(limit: $limit, nextToken: $nextToken) {
      items {
        description
        id
        link
        order
        title
      }
      nextToken
    }
  }
`;
export const translationListJobs = /* GraphQL */ `
  query TranslationListJobs($limit: Int, $nextToken: String) {
    translationListJobs(limit: $limit, nextToken: $nextToken) {
      items {
        contentType
        createdAt
        id
        jobIdentity
        jobName
        jobOwner
        jobStatus
        languageSource
        languageTargets
        sourceKey
        sourceStatus
        translateCallback
        translateKey
        translateStatus
      }
      nextToken
    }
  }
`;
